<template>
  <page>
    <div
      v-if="isLoading"
      class="main-container full-height base-scrollbar bg-white"
    >
      <div class="flex h-full items-center justify-center">
        <brightbid-loader class="">Fetching budget suggestions</brightbid-loader>
      </div>
    </div>
    <div v-if="!isLoading">
      <page-section
        full-width
        bottom-border
        class="relative"
      >
        <page-title>
          <div class="flex items-center gap-4">
            <ic-arrow
              class="cursor-pointer"
              @click="backToSiteCampaign"
            />
            <p>Manage Budgets</p>
          </div>
        </page-title>
        <div class="justify-between flex">
          <div class="text-gray-700 text-sm font-normal mt-3">
            Optimize your budget with AI-driven suggestions. Reallocate your existing total budget to <br />
            maximize your ad spend cross campaigns. Includes all your BrightBid managed campaigns <br />
            except brand campaigns and shared budgets.
          </div>
          <div
            class="px-6 flex items-center gap-2 cursor-pointer"
            @click="openEditActivityHistoryModal"
          >
            <ic-history :size="16" />
            <p>Activity History</p>
          </div>
        </div>
      </page-section>
      <div
        id="manageBudgetList"
        class="base-scrollbar overflow-y-auto main-container"
      >
        <page-section
          full-width
          bottom-border
          class="relative bg-gray"
        >
          <div class="flex justify-between">
            <!-- TOTAL -->
            <div class="mt-2">
              <div class="text-neutral-950 text-xl font-bold">Total</div>
              <div class="flex mt-3">
                <div class="text-gray-500 text-sm font-bold">(Monthly Budget:</div>
                <div class="text-right text-gray-500 text-sm font-medium ml-1">
                  {{ headerStats.monthlyBudget }} {{ headerStats.currency }})
                </div>
              </div>
            </div>
            <div class="flex justify-between gap-13 md:mr-12">
              <!-- AVG SPEND -->
              <div class="mt-2 statsWidth">
                <div class="text-gray-700 text-sm font-bold">Avg. Spend</div>
                <div class="flex mt-2 mb-1">
                  <div class="text-right text-neutral-950 pNumLarge font-bold">{{ headerStats.avgDailySpend }}</div>
                  <div class="text-right text-neutral-950 text-sm font-medium ml-2">{{ headerStats.currency }}/day</div>
                </div>
                <div class="text-left text-gray-500 text-xs font-medium">
                  {{ headerStats.spendBudgetRatio ?? 0 }}% of budget
                </div>
              </div>
              <!-- BUDGET -->
              <div class="mt-2 statsWidth">
                <div class="text-gray-700 text-sm font-bold">Budget</div>
                <div class="flex mt-3 mb-1">
                  <div class="text-right text-neutral-950 pNumLarge font-bold">{{ headerStats.avgDailyBudget }}</div>
                  <div class="text-right text-neutral-950 text-sm font-medium ml-2">{{ headerStats.currency }}/day</div>
                </div>
              </div>
              <!-- SUGGESTED BUDGET -->
              <div class="suggestionCardWidth px-5 py-2 bg-white rounded-lg border justify-between inline-flex">
                <div class="flex">
                  <div>
                    <IcStars
                      :size="16"
                      class="text-indigo-500 mr-2 mt-1"
                    />
                  </div>

                  <div class="">
                    <div
                      v-if="isApplyAllButtonVisible"
                      class="text-gray-700 text-sm font-bold flex items-center"
                    >
                      Reallocate Budget
                    </div>
                    <div v-if="!isApplyAllButtonVisible">
                      <div class="text-gray-700 text-sm font-bold flex items-center">
                        All good! &nbsp;<span class="text-gray-700 text-sm font-normal"
                          >There are no new suggestions.</span
                        >
                      </div>
                    </div>
                    <div class="flex mt-2 mb-1">
                      <div class="text-right text-neutral-950 pNumLarge font-bold">
                        {{ headerStats.suggestedBudget }}
                      </div>
                      <div class="text-right text-neutral-950 text-sm font-medium ml-2">
                        {{ headerStats.currency }}/day
                      </div>
                    </div>
                    <!-- change total budget -->
                    <!-- hidden until api is here -->
                    <div
                      class="text-left text-bb-brand-purple text-xs font-medium cursor-pointer"
                      @click="openEditBudgetModal"
                    >
                      Change total budget
                    </div>
                  </div>
                </div>
                <div
                  v-if="isApplyAllButtonVisible"
                  class="my-auto"
                >
                  <MergeButtonRound
                    class="px-3 bg-purple"
                    @click="openApplyAllConfirmationModal"
                    >Apply All</MergeButtonRound
                  >
                </div>
              </div>
            </div>
          </div>
        </page-section>

        <page-section
          v-if="campaignsList.length > 0"
          full-width
          class="full-height"
        >
          <CampaignBudgetCard
            v-for="(campaign, index) in campaignsList"
            :key="campaign.name"
            :campaign="campaign"
            :class="index === campaignsList.length - 1 ? 'pbLast' : ''"
            @save-monthly-budget="saveMonthlyBudget"
          />
        </page-section>
      </div>
    </div>
    <bb-base-modal
      v-if="isConfirmModalVisible || isEditBudgetModalVisible"
      :width="modalConfig.width"
      :height="modalConfig.height"
      @close="
        isConfirmModalVisible = false
        isEditBudgetModalVisible = false
      "
    >
      <template #container>
        <apply-all-budget
          v-if="isConfirmModalVisible"
          @confirm="saveNewTotalMonthlyBudget"
          @close="isConfirmModalVisible = false"
        />
        <edit-budget-modal
          v-if="isEditBudgetModalVisible"
          :currency="headerStats.currency"
          :daily-budget-to-edit="dailyBudgetToEdit"
          :is-modal-loading="isModalLoading"
          @close="isEditBudgetModalVisible = false"
          @update-total-budget="updateTotalBudget"
        />
      </template>
    </bb-base-modal>
  </page>
</template>

<script>
import IcArrow from '@/components/icon/brightbid/ic-arrow.vue'
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import IcHistory from '@/components/icon/brightbid/ic-history.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import IcStars from '@/components/icon/brightbid/ic-stars.vue'
import CampaignBudgetCard from './campaignBudgetCard.vue'
import EditBudgetModal from '@/components/modals/EditBudgetModal'
import ActivityHistoryModal from '@/components/modals/ActivityHistoryModal'
import { mapActions, mapState } from 'vuex'
import Toast from '@/components/shared/Toast.vue'
import dayjs from 'dayjs'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import ApplyAllBudget from '@/components/modals/forms/ApplyAllBudget.vue'

export default {
  name: 'ManageBudget',

  components: {
    IcArrow,
    Page,
    PageSection,
    IcHistory,
    MergeButtonRound,
    IcStars,
    CampaignBudgetCard,
    // eslint-disable-next-line vue/no-unused-components
    EditBudgetModal,
    ApplyAllBudget,
    BrightbidLoader,
    BbBaseModal,
  },
  data() {
    return {
      isLoading: true,
      isModalLoading: false,
      headerStats: {
        totalBudget: 0,
        avgSpend: 0,
        budget: 0,
        suggestedBudget: 0,
        spendBudgetRatio: 0,
        currency: '',
      },
      dailyBudgetToEdit: 0,
      campaignsList: [],
      touchedCampaignIds: [],
      isConfirmModalVisible: false,
      isEditBudgetModalVisible: false,
      modalConfig: {},
      budgetTarget: '',
    }
  },
  computed: {
    ...mapState('site', ['selectedSite', 'siteDetails']),
    isApplyAllButtonVisible() {
      return this.campaignsList.some(campaign => campaign.suggestion_applied === false)
    },
  },
  async mounted() {
    if (!this.siteDetails) {
      await this.fetchSiteDetails(this.selectedSite.value)
    }
    this.budgetTarget = this.siteDetails.site_metric_target?.roas ? 'ROAS' : 'CPA'

    this.getSiteCampaignsBudgetSuggestions()
  },
  methods: {
    ...mapActions('site', ['fetchSiteDetails']),
    backToSiteCampaign() {
      this.$router.push({ query: { ...this.$route.query, state: undefined } })
    },
    openEditBudgetModal() {
      this.modalConfig = { width: '420px', height: '330px' }
      this.isEditBudgetModalVisible = true
    },
    openApplyAllConfirmationModal() {
      this.modalConfig = { width: '380px', height: '200px' }
      this.isConfirmModalVisible = true
    },
    openEditActivityHistoryModal() {
      this.$modal.show(
        ActivityHistoryModal,
        {
          onSubmit: this.updateTotalBudget,
          dailyBudgetToEdit: this.dailyBudgetToEdit,
          currency: this.headerStats.currency,
        },
        ActivityHistoryModal.modalProps,
      )
    },
    async getSiteCampaignsBudgetSuggestions() {
      this.isLoading = true
      try {
        const today = dayjs()
        // number of days in this month
        const daysInMonth = today.daysInMonth()
        const { data } = await this.$http.get(`common/site/${this.selectedSite.value}/budget-suggestion`)

        this.dailyBudgetToEdit = data.total_suggested_budget
        this.headerStats = {
          monthlyBudget: data.total_daily_budget * daysInMonth,
          avgDailySpend: data.total_avg_daily_spend,
          spendBudgetRatio: ((data.total_avg_daily_spend / data.total_daily_budget) * 100).toFixed(2),
          avgDailyBudget: data.total_daily_budget,
          suggestedBudget: data.total_suggested_budget,
          currency: data.currency,
        }
        this.campaignsList = data.campaigns.map(campaign => ({
          ...campaign,
          id: campaign.campaign_id,
          name: campaign.campaign_name,
          budget: campaign.budget,
          avgSpend: campaign.avg_spend,
          suggestedBudget: campaign.suggested_budget,
          currency: data.currency,
          previousBudget: campaign.last_activity?.previous_budget,
          adjustedBudget: campaign.last_activity?.adjusted_budget,
          pMax: campaign.campaign_type === 'PERFORMANCE_MAX',
        }))
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to fetch campaigns budget suggestions',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async updateTotalBudget(amount) {
      this.isModalLoading = true

      try {
        await this.$http.post(`/search/site/${this.selectedSite.value}/total-budget`, {
          amount: amount,
          target: this.budgetTarget,
        })
        this.$toast.success({
          component: Toast,
          props: {
            title: '',
            message: 'Great, your budget has been updated!',
            type: 'success',
          },
        })
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to update the total budget',
            type: 'error',
          },
        })
      } finally {
        this.isModalLoading = false
        this.isEditBudgetModalVisible = false
        await this.getSiteCampaignsBudgetSuggestions()
      }
    },
    async saveNewTotalMonthlyBudget() {
      this.isConfirmModalVisible = false
      this.isLoading = true
      try {
        await this.$http.post(`common/site/${this.selectedSite.value}/budget-suggestion/apply-all`, {})
        this.$trackGTMEvent('budget_suggestion_apply_all')
        this.$toast.success({
          component: Toast,
          props: {
            title: '',
            message: 'Great, your budget has been updated!',
            type: 'success',
          },
        })
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to update the campaign budget',
            type: 'error',
          },
        })
      } finally {
        await this.getSiteCampaignsBudgetSuggestions()
        this.isLoading = false
      }
    },
    async saveMonthlyBudget(budget, campaign_id) {
      if (this.touchedCampaignIds.includes(campaign_id)) return
      try {
        this.touchedCampaignIds.push(campaign_id)
        await this.$http.post(`common/site/${this.selectedSite.value}/budget-suggestion`, {
          campaign_id: campaign_id,
          amount: parseFloat(budget),
        })
        this.$trackGTMEvent('budget_suggestion_apply', { campaign_id: campaign_id })
        this.$toast.success({
          component: Toast,
          props: {
            title: '',
            message: 'Great, your budget has been updated!',
            type: 'success',
          },
        })
        await this.getSiteCampaignsBudgetSuggestions()
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to update the campaign budget',
            type: 'error',
          },
        })
      } finally {
        this.touchedCampaignIds = this.touchedCampaignIds.filter(id => id !== campaign_id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.full-height {
  height: 100vh;
}
.bg-gray {
  background: #f9fafb;
}
.bg-purple {
  background: #6366fa;
}

.main-container {
  overflow-y: auto;
  height: calc(100vh - 250px);
}

.full-height {
  height: 100vh;
}
.pbLast {
  padding-bottom: 100px;
}
.statsWidth {
  width: 130px;
}
.suggestionCardWidth {
  width: 346px;
}
</style>
