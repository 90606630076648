<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="showModal"
      ref="modalContainer"
      class="h-fit w-80 absolute left-0 top-0 z-20 bg-white rounded-lg transition duration-800 ease-in-out modal-container"
      :style="modalStyle"
      @close="$emit('close')"
    >
      <div>
        <div class="p-6 grid gap-y-6">
          <div>
            <p class="h4 text-sm font-medium pb-2">Strategy</p>
            <search-input
              :value="selectedBidStrategy"
              input-name="bidStrategy"
              input-id="bidStrategy"
              :options="biddingStrategyOptions"
              :has-prefix-icon="true"
              class="w-full"
              placeholder="Search Bid Strategy..."
              :disabled="selectedBidStrategy.disabled"
              @select-item="selectBidStrategy"
            />
            <div
              v-if="selectedBidStrategy.value === 'BRIGHTBID_AI'"
              class="text-bb-neutral-gray pt-1 text-xs"
            >
              The bidding strategy in Google Ads will be changed to Maximize clicks.
            </div>
            <div
              v-if="selectedBidStrategy.disabled"
              class="text-bb-neutral-gray pt-1 text-xs pt-6"
            >
              Managing this strategy in BrightBid is currently not supported, please update it in Google Ads.
            </div>
          </div>
          <div v-if="targetTypeOptions.length > 0">
            <select-dropdown
              v-if="targetTypeOptions.length > 1"
              :selection-value="selectedtargetType"
              :options="targetTypeOptions"
              class="z-20 w-32"
              position="bottomLeftToRight"
              @select-item="selectTarget"
            >
            </select-dropdown>
            <div
              v-if="targetTypeOptions.length == 1"
              class="text-sm font-medium pb-2"
            >
              {{ targetTypeOptions[0].label }}
              <span class="text-bb-neutral-gray">{{ selectedBidStrategy.requiredTarget ? '' : '(Optional)' }}</span>
            </div>
            <text-input
              v-model="biddingStrategyForm.targetValue"
              input-name="targetValue"
              input-id="targetValue"
              class="w-full bb-text-secondary-gray pNumLarge"
              placeholder="9999"
              :error-message="$v.biddingStrategyForm.targetValue.$error ? 'Please enter a positive number' : ''"
              @input="$emit('set-editing-state', true)"
            >
              <template #suffix>
                <div class="absolute right-0 top-0 z-10 h-10 py-2 px-4">
                  <span v-if="unit === 'percentage'"> % </span>
                  <span v-else>{{ currency }}</span>
                </div>
              </template>
            </text-input>
          </div>
          <div class="flex flex-row-reverse gap-4 justify-center w-full">
            <merge-button-round
              :disabled="isLoading || (selectedBidStrategy.disabled && selectedBidStrategy.value !== 'PERFORMANCE_MAX')"
              button-type="primary"
              class="w-1/2"
              @click="submit"
            >
              <p>Save</p>
            </merge-button-round>
            <merge-button-round
              :disabled="isLoading"
              button-type="secondary"
              class="w-1/2"
              @click="$emit('close')"
            >
              <p>Cancel</p>
            </merge-button-round>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import TextInput from '@/components/input/brightbid/TextInput.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import SelectDropdown from '@/components/input/brightbid/SelectDropdown'
import {
  BID_STRATEGIES,
  BID_STRATEGIES_TARGET_OPTIONS_MAPPING,
  BID_STRATEGIES_TARGET_TYPES,
} from '../../core/constants'
import { required, minValue, decimal } from 'vuelidate/lib/validators'

import { enums } from 'google-ads-api/build/src/protos/autogen/enums.js'
const { BiddingStrategyType } = enums

export default {
  name: 'EditBidstrategyModal',
  components: {
    TextInput,
    MergeButtonRound,
    SearchInput,
    SelectDropdown,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    parentElementPosition: {
      type: Object,
      default: function () {
        return { top: 0, right: 0 }
      },
    },
  },
  validations() {
    return {
      biddingStrategyForm: {
        bidStrategy: { required },
        targetValue: { required: this.requiredValidator, decimal, minValue: minValue(0.0001) },
        targetType: {},
      },
    }
  },
  data() {
    return {
      biddingStrategyForm: {
        bidStrategy: '',
        targetValue: '',
        targetType: '',
      },
      unit: '',
      targetTypeOptions: [],
      selectedtargetType: {
        label: '',
        value: '',
      },
      selectedBidStrategy: {},
      customStrategies: [],
      modalStyle: { top: 0, right: 0 },
    }
  },
  computed: {
    biddingStrategyOptions() {
      const strategyOptions = BID_STRATEGIES.map(item => {
        return {
          label: item.label,
          value: item.value,
          key: item.key,
          disabled: item.disabled,
          icon: item.icon,
          requiredTarget: item.requiredTarget,
        }
      })
      return [...strategyOptions, ...this.customStrategies]
    },
    currency() {
      return this.selectedRow.currency
    },
  },
  watch: {
    selectedRow() {
      this.customStrategies = []
      if (!this.selectedRow) {
        return
      }
      this.selectedBidStrategy = this.getBiddingStrategy(this.selectedRow)
      this.biddingStrategyForm = {
        bidStrategy: this.selectedBidStrategy.value,
        targetValue: this.selectedRow.target_value,
        targetType: this.selectedRow.target_type,
      }
      this.updateTargetTypeOptions()
      this.updateSelectedTargetType()
      this.updateTargetValue()
    },
    parentElementPosition() {
      this.$nextTick(() => {
        this.updateModalPosition()
      })
    },
  },
  methods: {
    updateModalPosition() {
      const offset = 16
      const buffer = 10
      const defaultHeight = 270
      const defaultWidth = 320
      const modalHeight = this.$refs.modalContainer?.offsetHeight || defaultHeight
      const modalWidth = this.$refs.modalContainer?.offsetWidth || defaultWidth
      const parentTop = this.parentElementPosition.top
      const parentLeft = this.parentElementPosition.left
      const viewportHeight = window.innerHeight

      const hasOverflow = parentTop + modalHeight + offset + buffer > viewportHeight

      this.modalStyle = {
        top: hasOverflow ? `${parentTop - modalHeight + offset}px` : `${parentTop + offset}px`,
        left: `${parentLeft - offset - modalWidth}px`,
      }
    },
    getBiddingStrategy(item) {
      if (item.is_brightbid_bid_strategy) {
        return BID_STRATEGIES.find(i => i.value === 'BRIGHTBID_AI')
      } else if (item.is_performance_max_bid_strategy) {
        return BID_STRATEGIES.find(i => i.value === 'PERFORMANCE_MAX')
      } else {
        let bidStrategy = BID_STRATEGIES.find(i => i.label === item.bid_strategy_display_name)
        if (!bidStrategy) {
          bidStrategy = {
            label: item.bid_strategy_display_name,
            value: item.bid_strategy_display_name.replace(' ', '_').toUpperCase(),
            disabled: true,
            icon: item.bid_strategy_display_name?.toLowerCase().includes('google') ? 'google-g-icon' : '',
            requiredTarget: false,
          }
          this.customStrategies.push(bidStrategy)
        }
        return bidStrategy
      }
    },
    updateTargetTypeOptions() {
      this.targetTypeOptions = BID_STRATEGIES_TARGET_OPTIONS_MAPPING[this.selectedBidStrategy?.value] ?? []
    },
    updateSelectedTargetType() {
      if (this.targetTypeOptions.length === 0) {
        this.selectedtargetType = {}
      } else if (this.targetTypeOptions.length === 1) {
        this.selectedtargetType = this.targetTypeOptions[0]
      } else if (this.selectedRow.is_performance_max_bid_strategy) {
        /* 
          For Performance Max case 
          Target ROAS translates to MAXIMIZE_CONVERSION_VALUE
          Target CPA translates to MAXIMIZE_CONVERSIONS
        */
        const targetType = this.biddingStrategyForm.targetType?.toLowerCase()
        const strategyType =
          targetType === 'cpa'
            ? BiddingStrategyType.MAXIMIZE_CONVERSIONS
            : BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE
        this.selectedtargetType = this.targetTypeOptions.find(i => i.value === BiddingStrategyType[strategyType])
      } else {
        this.selectedtargetType = this.selectedRow.target_type
          ? this.targetTypeOptions.find(i => i.value === this.biddingStrategyForm.targetType)
          : this.targetTypeOptions[0]
      }
      this.unit = this.targetTypeOptions.length > 0 ? this.selectedtargetType.unit : ''
    },
    requiredValidator(value) {
      return this.selectedBidStrategy.requiredTarget ? required(value) : true
    },
    selectBidStrategy(item) {
      this.$emit('set-editing-state', true)
      this.selectedBidStrategy = item
      this.updateTargetTypeOptions()
      this.updateSelectedTargetType()
    },
    selectTarget(item) {
      this.$emit('set-editing-state', true)
      this.unit = item.unit
      this.selectedtargetType = item
      this.updateTargetValue()
    },
    updateTargetValue() {
      const targetValue = this.selectedRow.target_value
      if (!targetValue) {
        this.biddingStrategyForm.targetValue = ''
      } else {
        const isPercent = ['ROAS', 'TARGET_ROAS', 'MAXIMIZE_CONVERSION_VALUE'].includes(this.selectedtargetType.value)
        this.biddingStrategyForm.targetValue = isPercent ? targetValue * 100 : targetValue
      }
    },
    createPayload() {
      const bidStrategyValue = this.selectedBidStrategy.value
      const bidStrategyName = this.selectedtargetType.value
      const targetValue = this.biddingStrategyForm.targetValue

      const isBrightbidStrategy = bidStrategyValue === 'BRIGHTBID_AI'
      const isGoogleMaximizeClicks = bidStrategyValue === 'GOOGLE_MAXIMIZE_CLICKS'
      const isPercent = ['ROAS', 'TARGET_ROAS', 'MAXIMIZE_CONVERSION_VALUE'].includes(bidStrategyName)

      const payload = {
        is_brightbid_bid_strategy: isBrightbidStrategy,
        google_bid_strategy_name: isBrightbidStrategy ? null : bidStrategyName,
        target_type: isBrightbidStrategy ? bidStrategyName : null,
        target_value: !targetValue ? null : isPercent ? targetValue / 100 : targetValue,
        target_type_raw: BID_STRATEGIES_TARGET_TYPES[this.selectedtargetType.value],
        bid_strategy_label: this.selectedBidStrategy.label,
      }

      if (isGoogleMaximizeClicks && targetValue) {
        payload.max_cpc = targetValue
      }

      return payload
    },
    submit() {
      this.$v.biddingStrategyForm.$touch()
      if (this.$v.biddingStrategyForm.targetValue.$error) {
        return
      }
      const data = this.createPayload()
      this.$emit('submit-form', data)
    },
  },
}
</script>

<style scoped>
.modal-container {
  box-shadow: 0px 2px 14px 0px rgba(136, 152, 170, 0.15);
}
</style>
